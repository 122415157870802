/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ThemeManifestTheme } from '@/styles/manifest';
import { Button } from '@/styles/Verizon/Button';
import { Checkbox } from '@/styles/Verizon/Checkbox';
import { Icon } from '@/styles/Verizon/Icon';
import { Link } from '@/styles/Verizon/Link';
import { palette } from '@/styles/Verizon/palette';
import { Radio } from '@/styles/Verizon/Radio';
import { shape } from '@/styles/Verizon/shape';
import { TextField } from '@/styles/Verizon/TextField';
import { typography } from '@/styles/Verizon/type';

export const VerizonTheme: ThemeManifestTheme = {
	inheritFrom: 'Default',
	components: [palette, typography, shape, TextField, Button, Checkbox, Icon, Radio, Link],
};
