/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ThemeOptions } from '@mui/material/styles';

export const palette: ThemeOptions = {
    palette: {
        primary: {
            main: '#050e9f',
            dark: '#050f9f',
            light: '#e3e9ef',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#d81f41',
            light: '#f6f9fc',
            dark: '#c74001',
            contrastText: '#ffffff',
        },
        text: {
            main: '#050e9f',
            primary: '#050e9f',
            secondary: '#c74001',
            disabled: '#e3e9ef',
            highlight: '#d81f41',
            alert: '#d81f41',
            expandedMenu: '#ffffff',
        },
        base: {
            main: '#050e9f',
            contrastText: '#ffffff',
        },
        action: {
            hover: '#f6f9fc',
            disabled: '#e3e9ef',
            disabledBackground: '#f6f9fc',
        },
        background: {
            main: '#ffffff',
            default: '#f6f9fc',
            paper: '#ffffff',
            transparency: 'cc', // used to combine hex colors with transparency (00-ff), e.g., #ffffffcc
            contrastText: '#050e9f',
        },
        divider: 'rgba(0, 0, 0, 0.12)',
        border: {
            dark: '#050e9f',
            alert: '#d81f41',
            footer: '#c74001',
        },
        textField: {
            border: '#050e9f',
            borderHovered: '#050f9f',
            background: '#ffffff',
            disabledBackground: '#e3e9ef',
        },
        button: {
            primary: '#050e9f',
            primaryHover: '#050f9f',
            secondary: '#ffffff',
            secondaryHover: '#f6f9fc',
            contrastText: '#ffffff',
            disabled: '#e3e9ef',
            disabledText: '#050e9f',
        },
    },
} as ThemeOptions;

