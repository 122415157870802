/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ThemeManifestTheme } from '@/styles/manifest';
import { Button } from '@/styles/Tracfone/Button';
import { Checkbox } from '@/styles/Tracfone/Checkbox';
import { Icon } from '@/styles/Tracfone/Icon';
import { Link } from '@/styles/Tracfone/Link';
import { palette } from '@/styles/Tracfone/palette';
import { Radio } from '@/styles/Tracfone/Radio';
import { shape } from '@/styles/Tracfone/shape';
import { TextField } from '@/styles/Tracfone/TextField';
import { typography } from '@/styles/Tracfone/type';

export const TracfoneTheme: ThemeManifestTheme = {
	inheritFrom: 'Default',
	components: [palette, typography, shape, TextField, Button, Checkbox, Icon, Radio, Link],
};
